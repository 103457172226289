.registerView {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    position: relative;
    text-align: center;
}

.logosContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 80%;
    flex-wrap: wrap
}

.logoContentLeft, .logoContentRight {
    height: 40px;
    margin-bottom: 25px;
}

.registerView > h1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
    margin-top: 75px;
}

.registerView > img {
    border-radius: 17px;
    margin-bottom: 50px;
}

.registerView > p {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
}

.registerForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.registerForm > input, .registerForm > label {
    border-color: gray;
    height: 60px;
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
}

.registerForm > select {
    border: 1px solid;
    border-color: gray;
    height: 60px;
    font-size: 16px;
    color: gray;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: #fff;
}

.registerForm > select:focus {
    border-color: black;
    outline: none;
    border-width: 2px;
}

.registerFormEnableButton {
    width: 50%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-top: 30px;
    padding: 10px 50px;
    border: 1px solid;
    border-color: #807ddd;
    background-color: #807ddd;
    color: #fff;
    line-height: 24px;
    cursor: pointer;
}

.registerFormDisableButton {
    width: 50%;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    margin-top: 30px;
    padding: 10px 50px;
    border: 1px solid;
    border-color: #dedee2;
    background-color: #dedee2;
    color: #a3a3a3;
    line-height: 24px;
}


.firstNameInput {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
    border-top: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
}

.firstNameInput:focus, .lastNameInput:focus {
    border-color: black;
    outline: none;
    border-width: 2px;
    border-bottom: 2px solid black;
}

.lastNameInput {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid;
}

.phoneNumberInput {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top: none;
    border-bottom: none;
    border-left: 1px solid;
    border-right: 1px solid;
}

.phoneNumberInput:focus {
    border-color: black;
    outline: none;
    border-width: 2px;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
}

.firstCheckboxInput {
    border-top: none;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    text-align: start;
    display: flex;
    align-items: center;
    height: 70px !important;
}

.secondCheckboxInput {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    border-bottom: 1px solid;
    border-left: 1px solid;
    border-right: 1px solid;
    text-align: start;
    display: flex;
    align-items: center;
    height: 70px !important;
}

.customCheckboxLeft {
    align-self: center;
    margin-right: 15px;
}

.customCheckboxRight {
    align-self: center;
    margin-left: 15px;
}

.recaptcha {
    margin-top: 30px;
}

.iconRow {
    flex-direction: row;
    display: flex;
    margin-top: 15px;
}

.iconRow > p {
    margin-top: 8px;
}

.iconSuccsess {
    font-size: 35px;
    color: #3AAFA9;
    text-align: center;
    margin-right: 5px;
}

.iconSuccsess > p {
    font-size: 25px;
    color: #3AAFA9;
}

.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalContent {
    width: 680px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
}

.closeButtonRight {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 36px;
    color: #000;
}

.closeButtonLeft {
    position: absolute;
    top: 0px;
    left: 10px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 36px;
    color: #000;
}

.firstModalContentLeft {
    margin-bottom: 20px;
    margin-right: 20px;
    font-weight: 500;
}

.firstModalContentRight {
    margin-bottom: 20px;
    margin-left: 20px;
    font-weight: 500;
}

.secondModalContent {
    font-weight: 500;
}

.logoLeft {
    position: absolute;
    left: 0;
    top: 20px;
    width: 180px
}

.logoRight {
    position: absolute;
    right: 0;
    top: 20px;
    width: 180px
}

@media screen and (max-width: 740px) {
    .logoContentLeft, .logoContentRight {
        height: 35px;
    }
}

@media screen and (max-width: 650px) {
    .logoContentLeft, .logoContentRight {
        height: 30px;
    }
}

@media screen and (max-width: 570px) {
    .logosContainer {
        width: 90%;
        justify-content: start;
    }

    .logoContentLeft {
        margin-right: 50px;
    }

    .logoContentRight {
        margin-left: 50px;
    }
}

@media screen and (max-width: 851px) {
    .registerView {
        width: 100%;
        padding: 20px;
    }

    .logosContainer {
        margin-top: 0px;
    }

    .registerView > h1 {
        width: 90%;
        margin-top: 75px;
        font-size: 22px;
    }

    .registerView > img {
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 20px;
    }

    .registerView > p {
        width: 75%;
        margin-bottom: 30px;
    }

    .registerForm {
        width: 100%;
    }

    .registerForm > input, .registerForm > label, .registerForm > select {
        font-size: 14px;
    }

    .registerFormEnableButton {
        width: 70%;
        font-size: 16px;
        margin-top: 20px;
        padding: 10px 30px;
    }
    
    .registerFormDisableButton {
        width: 70%;
        font-size: 16px;
        margin-top: 20px;
        padding: 10px 30px;
    }

    .firstCheckboxInput, .secondCheckboxInput {
        height: 100px !important;
        padding-right: 10px;
    }

    .modalContent {
        width: 80%;
    }

    .logoLeft {
        left: 10px;
        width: 130px
    }

    .logoRight {
        right: 10px;
        width: 130px
    }
}