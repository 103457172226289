.faqView {
    width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    text-align: center;
}

.faqView > h1 {
    margin-bottom: 50px;
}

.faqContainerLeft {
    border-radius: 11px;
    padding: 0px 30px;
    background-color: #F5F5F7;
    margin-top: 25px;
    cursor: pointer;
    text-align: left;
}

.faqContainerRight {
    border-radius: 11px;
    padding: 0px 30px;
    background-color: #F5F5F7;
    margin-top: 25px;
    cursor: pointer;
    text-align: right;
}

.faqContainerIcon {
    font-size: 16px;
    margin-top: auto;
    margin-bottom: auto;
}

.questionContainerLeft, .questionContainerRight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.questionContainerLeft > h3, .questionContainerRight > h3 {
    font-weight: 600;
}

.faqContainerLeft > p, .faqContainerRight > p {
    margin-top: 0px;
    padding-bottom: 25px;
}

.faqDescription {
    margin-top: 50px;
}

.registerButton {
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 851px) {
    .faqView {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 25px;
        padding: 20px;
    }

    .faqView > h1 {
        font-size: 22px;
    }

    .faqContainerLeft, .faqContainerRight {
        padding: 0px 20px;
        margin-top: 20px;
    }

    .faqContainerIcon {
        font-size: 14px;
    }

    .questionContainerLeft > h3 {
        padding-right: 20px;
        font-size: 17px;
    }

    .questionContainerRight > h3 {
        padding-left: 20px;
        font-size: 17px;
    }
}